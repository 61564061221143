import { seededRandom } from '~/utils/math';

/**
 * Array Shuffle (Fisher–Yates shuffle)
 */
export const shuffleArray = <T>(array: T[], baseSeed?: number): T[] => {
    let currentIndex = array.length;
    let seed = baseSeed || 0;
    let randomIndex;

    while (currentIndex !== 0) {
        seed++;
        if (baseSeed) {
            randomIndex = Math.floor(seededRandom(seed) * currentIndex);
        } else {
            randomIndex = Math.floor(Math.random() * currentIndex);
        }
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex]!,
            array[currentIndex]!,
        ];
    }

    return array;
};

/**
 * Compare 2 arrays to see if they contain the same content regardless of ordering
 */
export const compareArrayContents = <T>(a: T[], b: T[]): boolean =>
    a.length === b.length && a.every((item) => b.includes(item));
